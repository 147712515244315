<template>
    <div class="produce-list">
        <el-tabs v-model="status" @tab-click="searchOrder">
            <el-tab-pane v-for="(item, index) in typeList" :key="index" :label="item.name" :name="item.status">
                <div style="display: flex">
                    <div>
                        <el-input size="small" style="width: 350px" placeholder="输入商品名称或订单号搜索" v-model="orderName">
                            <template slot="append">
                                <div class="hand op" @click="getOrderList">订单搜索</div>
                            </template>
                        </el-input>
                    </div>
                    <div style="border-right: 2px #BDBFC1 solid; margin: 2px 30px"></div>
                    <div style="display: flex; margin-top: 2px; margin-right: 20px">
                        <span style="margin-top: 2px">订单金额（元）</span>
                        <el-input style="width: 140px" size="mini" type="number" min="0" v-model="minPrice">
                        </el-input>
                        <span style="color: #BDBFC1; margin: 0 10px">-</span>
                        <el-input style="width: 140px" size="mini" type="number" min="0" v-model="maxPrice">
                        </el-input>
                    </div>
                    <div @click="searchOrder" class="search-btn hand op">筛选</div>
                </div>
                <div class="title-box">
                    <el-date-picker
                        style="margin-top: 6px; width: 260px"
                        v-model="orderTime"
                        type="daterange"
                        value-format="yyyy:MM:dd"
                        align="right"
                        unlink-panels
                        size="small"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :picker-options="pickerOptions"
                        @change="checkoutDate">
                    </el-date-picker>

                    <span style="margin: 0 260px 0 60px">订单详情</span>
                    <span>单价</span>
                    <span style="margin: 0 130px 0 100px">数量</span>
                    <span>金额</span>
                    <span style="margin: 0 120px 0 150px">订单状态</span>
                    <span>操作</span>
                </div>

                <div class="order-box" v-for="(item, index) in orderList" :key="index">
                    <ProduceCard :type="type" :orderData="item" @updateList="updateOrderList"></ProduceCard>
                </div>
            </el-tab-pane>

        </el-tabs>
    </div>
</template>

<script>

import ProduceCard from "./ProduceCard";
export default {
    name: "ProduceList",
    components: {ProduceCard},
    data() {
        return {
            orderList: [],
            page: 1,
            pageSize: 10,
            more: 1,
            status: '-1',
            userType: '0',

            orderName: '',
            orderTime: [],
            minPrice: '',
            maxPrice: '',
            strTime: '',
            endTime: '',

            pickerOptions: {
                shortcuts: [{
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                },{
                    text: '最近一年',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                        picker.$emit('pick', [start, end]);
                    }
                },
                ]
            },

            typeList: [
                {status: '-1', name: '全部'},
                {status: '0', name: '待支付'},
                {status: '1', name: '待发货'},
                {status: '2', name: '待收货'},
                {status: '3', name: '已到货'},
                {status: '4', name: '待评价'},
                {status: '5', name: '已评价'},
                // {status: '6', name: '空'},

                {status: '7', name: '售后'},
                ],
        }
    },
    props: {
        type: {
            type: Number,
            default() {
                return 1
            }
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    created: function () {

    },
    mounted: function () {
        this.getOrderList();
        let that = this;
        this.$EventBus.$on("payMsg", (data) => {
            this.$nextTick(() => {
                if (data.code == 100) {
                    that.getOrderList()
                }
            });
        });
        window.onscroll = function() {
          var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
          //变量windowHeight是可视区的高度
          var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
          //变量scrollHeight是滚动条的总高度
          var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
          if(scrollHeight - scrollTop - windowHeight <= 0){
            if (that.more == 1) {
              that.getOrderList()
            }else{
              console.log("已加载完");
            }
          }
        }
    },
    methods: {
        // 按照时间搜索
        checkoutDate(time) {
            if (time) {
                this.strTime = time[0];
                this.endTime = time[1]
            } else {
                this.strTime = '';
                this.endTime = ''
            }
            this.getOrderList()
        },

        // 搜索订单
        searchOrder() {
            this.page = 1;
            this.more = 1;
            this.orderList = [];
            this.getOrderList()
        },

        // 更新订单列表
        updateOrderList() {
            this.page = 1;
            this.more = 1;
            this.orderList = [];
            this.getOrderList()
        },

        // 获取订单列表
        getOrderList() {
            var param = {};
            param.page = this.page;
            param.pageSize = this.pageSize;
            param.like = this.orderName
            param.type = this.type;
            param.isPay = this.status;
            param.strTime = this.strTime;
            param.endTime = this.endTime;
            this.newApi.getShopOrderList(param).then(res => {
                if (res.isSuccess === 1) {
                  for (const item of res.data) {
                    this.orderList.push(item)
                  }
                  if (res.data.length < 10) {
                    this.more = 0;
                  } else {
                    this.page ++;
                  }

                }
            })
        }
    }
}
</script>

<style scoped>
.produce-list .search-btn {
    padding: 6px 12px;
    font-size: 14px;
    color: #FFFFFF;
    border-radius: 5px;
    background-color: #2970FF;
}

.produce-list .title-box {
    width: 100%;
    height: 46px;
    background-color: #F5F5F5;
    display: flex;
    line-height: 46px;
    margin: 20px 0;
    padding: 0 20px;
}

.produce-list .order-box {
    margin-bottom: 20px;
}

/deep/ .title-box .el-input__inner {
    background-color: #F5F5F5;
}

/deep/ .el-tabs__item {
    font-size: 18px;
    text-align: center;
    margin-bottom: 10px;
    width: 120px;
    font-weight: 600;
}

</style>