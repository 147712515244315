<template>
    <div class="produce-card">
        <div class="title-info">
            <div style="display: flex">
                <div>
<!--                    成交时间：{{orderData.createTime}}-->
                </div>
                <div style="margin: 0 180px 0 40px">
                    订单号：{{orderData.orderNo}}
                </div>
                <div style="display: flex" v-if="orderData.isShop === 1">
                    <span>店铺：{{orderData.shopName}}</span>
                </div>
            </div>

            <div class="hand op" style="color: #031F88; font-weight: 600; margin-right: 10px">
                <span v-if="type === 1" @click="toMessage(orderData.goodsList[0].shopUuid, orderData.shopName)"><i class="el-icon-service"></i> 联系商家</span>
                <span v-if="type === 2" @click="toMessage(orderData.goodsList[0].uuid, '买家')"><i class="el-icon-service"></i> 联系买家</span>
            </div>
        </div>
        <div style="display: flex">
            <div>
                <div class="body-box" v-for="(item, index) in orderData.goodsList" :key="index">
                    <div style="padding: 10px;">
                        <div style="margin-bottom: 10px; font-size: 13px; color: #999999" v-if="item.isPay > 0">
                            <span style="margin-right: 20px;">{{item.createTime}}</span>
                            <span>订单号：{{item.smallOrderNo}}</span>
                        </div>
                        <div style=" display: flex;">
                            <img :src="item.skuList.images[0]" style="width: 90px; height: 90px; margin-right: 10px;">
                            <div style="width: 458px">
                                <div class="content-bord">{{item.goodsName}}</div>
                                <div class="content-normal" style="margin-top: 10px">
                                    <div>
                            <span v-for="(key, index) in item.skuList.specValues" :key="index">
                                    {{Object.keys(key)[0]}}: {{Object.values(key)[0]}}
                            </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="body-option" style="width: 160px; border-right: 1px solid #E9E9E9;">￥{{item.unitPrice}}</div>
                    <div class="body-option" style="width: 120px; border-right: 1px solid #E9E9E9;">{{item.count}}</div>
                    <div class="body-option" style="width: 200px; border-right: 1px solid #E9E9E9;">
                        <div class="content-bord">￥{{utils.keepTwoDecimalFull(item.unitPrice * item.count)}}</div>
                        <div class="content-normal">（含运费：￥0.00）</div>
                    </div>
                    <div class="body-option" style="width: 200px; border-right: 1px solid #E9E9E9;">
                        <div v-for="(type, indexs) in orderStatusList" :key="indexs">
                            <span class="content-status" :style="{color: type.color}" v-if="item.isPay === type.status">
                                <span v-if="[1,5,8].indexOf(item.isAftersale.refundIsAgree) === -1">{{type.name}} </span>
                                <span v-if="item.isPay === 7">
                                    <span v-for="(option, optionIndex) in refundList" :key="optionIndex">
                                        <span :style="[1,5,8].indexOf(item.isAftersale.refundIsAgree) !== -1 ? 'color: #8BC34A' : ''" v-if="item.isAftersale.refundIsAgree === option.status">{{option.name}}</span>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div v-if="item.isPay === 7" style="margin-top: 10px">
                            <div @click="applyRefund(item.smallOrderNo)" class="content-status hand op" style="color: #2970FF; margin-top: 10px">查看详情</div>
                        </div>
                        <div v-if="item.isPay > 1">
                            <el-popover placement="bottom" width="360" trigger="hover">
                                <div>
                                    <div style="font-size: 14px; color: #999999; max-height: 240px; width: 320px; overflow: auto">物流信息</div>
                                    <div slot="reference" class="content-status" style="color: #2970FF">查看物流信息 <i class="el-icon-caret-bottom" style="font-weight: 600"></i></div>
                                </div>
                            </el-popover>
                        </div>
                    </div>
                    <div class="body-option" style="text-align: center; width: 130px" v-if="type === 1 && item.isPay !== 0">
                        <div v-if="item.isPay === 3" style="margin-bottom: 10px">
                            <div class="btn hand op" @click="makeReceive(item.smallOrderNo)" style="background-color: #FF0000; color: #FFFFFF;">确认收货</div>
                        </div>
                        <div v-if="item.isPay === 4" style="margin-bottom: 10px">
                            <div class="btn hand op" @click="toAppraise(item)" style="background-color: #FF0000; color: #FFFFFF;">评价</div>
                        </div>
                        <div v-if="item.isPay === 1">
                            <div class="content-bord hand op" @click="applyRefund(item.smallOrderNo)" style="color: #2970FF;">申请退款</div>
                        </div>
                        <div v-if="item.isPay === 3">
                            <div class="content-bord hand op" @click="applyRefund(item.smallOrderNo)" style="color: #2970FF;">申请售后</div>
                        </div>
                    </div>


                    <div class="body-option" style="text-align: center; width: 140px" v-if="type === 2">
                        <div v-if="item.isPay === 1">
                            <div class="btn hand op" @click="function () {sendId = item.smallOrderNo; showSend = true}" style="background-color: #FF0000; color: #FFFFFF;">确认发货</div>
                        </div>
                        <div v-if="item.isPay === 2">
                            <div class="btn hand op" @click="haveArrived(item.smallOrderNo)" style="background-color: #FF0000; color: #FFFFFF;">确认到货</div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="orderData.goodsList[0].isPay === 0 && type === 1" style="width: 140px;text-align: center;justify-content: center;font-weight: 600;display: flex; align-items: center;">
                <div>
                    <span class="hand op btn" @click="payTheOrder(orderData)" style="background-color: #FF0000; color: #FFFFFF; margin-bottom: 10px">继续支付</span>
                    <div class="content-bord hand op" @click="cancelOrder(orderData.orderNo)" style="color: #2970FF">取消订单</div>
                </div>
            </div>
        </div>

        <!--发货确认弹窗-->
        <el-dialog :visible.sync="showSend" width="30%" :show-close="false" class="event-form" :close-on-click-modal="false">
            <div class="box-head">
                填写确认信息
                <img src="../../imgs/cancel.png" style="width: 32px; height: 32px;position: absolute;right: 30px;top: 14px;cursor: pointer;" @click="showSend = false">
            </div>
            <div style="padding: 20px 40px; overflow: hidden">
                <div style="margin-bottom: 40px">
                    <div style="margin-right: 30px">
                        <span>物流单号：</span>
                        <el-input style="width: 80%" size="small" v-model="reviewData.expressNumber"></el-input>
                    </div>
                </div>

                <div>
                    <el-checkbox :true-label="1" :false-label="0" v-model="reviewData.isInvoice">已开发票</el-checkbox>
                </div>

                <div style="margin: 20px 0;">
                    <div @click="makeSendGoods" class="foot-btn hand op" style="background-color: #2970FF; color: #FFFFFF; margin: 0 auto">确认发货</div>
                </div>
            </div>
        </el-dialog>

        <!--支付弹窗-->
        <el-dialog  :visible.sync="showPay" width="40%" :show-close="false" class="event-form" :close-on-click-modal="false">
            <div class="box-head">
                请支付您的产品费用
                <img src="../../imgs/cancel.png" style="width: 32px; height: 32px;position: absolute;right: 30px;top: 14px;cursor: pointer;" @click="showPay = false">
            </div>
            <div style="padding: 20px 40px; overflow: hidden">
                <div style="margin-bottom: 20px;display: flex; justify-content: space-between; height: 50px; border-top: 1px solid #DEDDDD; border-bottom: 1px solid #DEDDDD;">
                    <div style="font-size: 18px; font-weight: 600; line-height: 50px">
                        <span>支付金额：</span>
                        <span style="color: #EE3F4D">￥{{payOne.price}}</span>
                    </div>
                </div>
                <!-- 快捷卡支付  -->
                <div>
                    <div v-if="payOne.price > 0" style="width: 400px;margin-left: 28%;margin-top: 10px;">
                        <!--        支付类型参考-->
                        <aa-da-pay-code type="3" :payAmt="payOne.price" :payType="10" OrderType="10" :orderNo="newOrderNo" @change="changeOrder($event)" ref="child"></aa-da-pay-code>
                    </div>
                    <div style="width: 180px;height: 52px;background: #EE3F4D;float: left;margin:20px 34%" @click="toPaySelect">
                        <div style="width: 80px;height: 24px;font-size: 20px;color: #FFFFFF;margin:14px 0 0 50px;cursor: pointer">立即支付</div>
                    </div>
                </div>
                <!--                <div>-->
                <!--                    <div v-if="count * $route.params.unitPrice > 0" style="width: 400px;margin-left: 28%;margin-top: 10px;">-->
                <!--                        &lt;!&ndash;        支付类型参考&ndash;&gt;-->
                <!--                        <OrganPayCode @closeSubPay="closeSubPay" v-if="showOrganPay" type="3" :payAmt="count * $route.params.unitPrice" :payType="10" OrderType="10" :orderNo="orderNo" :collectionUuid="shopInfo.uuid" ref="child"></OrganPayCode>-->
                <!--                    </div>-->
                <!--                    <div style="width: 180px;height: 52px;background: #EE3F4D;float: left;margin:20px 34%" @click="goOrganPay">-->
                <!--                        <div style="width: 80px;height: 24px;font-size: 20px;color: #FFFFFF;margin:14px 0 0 50px;cursor: pointer">立即支付</div>-->
                <!--                    </div>-->
                <!--                </div>-->
            </div>
        </el-dialog>
        <pay-loading v-if="loading == true"></pay-loading>

        <el-dialog class="show-message" :visible.sync="showMessage" width="940px" :close-on-click-modal="false">
            <div class="dialog-title">
                <div style="margin-left: 40%">
                    <span style="color: #0084FF">{{nickName}}</span>
                    <span>的私信</span>
                </div>
            </div>
            <el-divider></el-divider>
            <div style="padding: 20px">
                <ToMessage :uuid="uuid" :type="1"></ToMessage>
            </div>
        </el-dialog>

      <!--商品评价弹窗-->
      <el-dialog :close-on-click-modal="false" :visible.sync="showAppraise" width="32%" :show-close="false" class="event-form">
        <div class="top">
          服务评价
          <img src="../../imgs/cancel.png" style="width: 32px; height: 32px;position: absolute;right: 30px;top: 14px;cursor: pointer;" @click="closeEvent">
        </div>
        <div style="padding: 20px 40px">
          <div style="margin-bottom: 40px">
            <div class="title-form">商品评分</div>
            <el-rate v-model="appraiseData.goodsScore" color="#FF0000"></el-rate>
          </div>
          <div style="margin-bottom: 40px">
            <div class="title-form">评价内容</div>
            <el-input
                type="textarea"
                v-model="appraiseData.content"
                :show-word-limit="true"
                maxlength="500"
                :rows="5"
                placeholder="请输入序列说明">
            </el-input>
          </div>
          <div style="display: flex; justify-content: center">
            <div class="foot-btn hand op" style="background-color: #2970FF; color: #FFFFFF;" @click="addAppraise">立即评价</div>
          </div>
        </div>
      </el-dialog>
    </div>
</template>

<script>

import AaDaPayCode from "../../../../components/common/Pay/AaDaPayCode";
import ToMessage from "./ToMessage";
export default {
    name: "ProduceCard",
    components: {ToMessage, AaDaPayCode},
    data() {
        return {
            newOrderNo: '',
            showPay: false,
            loading: false,
            showOrganPay: false,
            payOne: {},
            orderStatusList: [
                {name: '待支付', color: '#F44336', status: 0},
                {name: '待发货', color: '#F44336', status: 1},
                {name: '待收货', color: '#F44336', status: 2},
                {name: '已到货', color: '#FF9800', status: 3},
                // {name: '已签收', color: '#8BC34A', status: 4},
                {name: '待评价', color: '#F44336', status: 4},
                {name: '已完成', color: '#8BC34A', status: 5},
                {name: '售后中', color: '#F44336', status: 7},
                {name: '已取消', color: '#F44336', status: 8},
            ],

            // 商家操作
            showSend: false,   // 确认发货弹窗
            sendId: '',
            reviewData: {
                expressNumber: '',
                isInvoice: 0
            },

            refundList: [
                {name: '待处理', status: 0},
                {name: '同意退款', status: 1},
                {name: '拒绝退款', status: 2},
                {name: '同意退货', status: 3},
                {name: '拒绝退货', status: 4},
                {name: '退货完成', status: 5},
                {name: '同意换货', status: 6},
                {name: '拒绝换货', status: 7},
                {name: '换货完成', status: 8},],

            showMessage: false,
            uuid: '',
            nickName: '',

            // 商品评价
            showAppraise: false,
            goodId: '',
            shopId: '',
            appraiseData: {
              goodsScore: 0,
              content: ''
            },
        }
    },
    props: {
        orderData: {
            type: Object,
            default() {
                return {
                }
            }
        },
        type: {
            type: Number
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    created: function () {

    },
    mounted: function () {
        let that = this;
        that.newOrderNo = that.orderData.orderNo;
    },
    methods: {
        // 评价
        toAppraise(item) {
            this.showAppraise = true;
            this.goodId = item.id
            this.shopId = item.shopId
        },

        addAppraise() {
           let params = {};
           params.evaluateId = this.goodId;
           params.shopId = this.shopId;
           params.type = 2;
           params.fid = 0;
           params.goodsScore = this.appraiseData.goodsScore;
           params.content = this.appraiseData.content;
           if (!params.content) {
              this.utils.msg('请填写评价内容');
              return
           }
          if (!params.goodsScore) {
            this.utils.msg('请对商品进行评分');
            return;
          }
           this.newApi.addShopAndServiceEvaluate(params).then(res => {
              if (res.isSuccess === 1) {
                  this.utils.sus(res.data);
                  this.showAppraise = false;
                  this.$emit('updateList')
              }
           })
        },

        // 关闭评价弹窗
        closeEvent() {
          this.showAppraise = false
        },

        // 私信
        toMessage(uuid, name) {
            this.uuid = uuid;
            if (name) {
                this.nickName = name;
                this.showMessage = true
            }
        },

        // 临时确认到货
        haveArrived(smallOrderNo) {
            this.newApi.sellerUpdateGoodsOrder({smallOrderNo: smallOrderNo, isPay: 3}).then(res => {
                if (res.isSuccess === 1) {
                    this.utils.sus(res.data);
                    this.showSend = false;
                    this.$emit('updateList')
                }
            })
        },

        // 取消订单
        cancelOrder(orderNo) {
            let that = this;
            this.utils.confirm('确定要取消此订单吗？', function () {
                that.newApi.cancelOrder({orderNo: orderNo}).then(res => {
                    if (res.isSuccess === 1) {
                        that.utils.sus(res.data);
                        that.$emit('updateList')
                    }
                })
            })

        },

        // 删除订单
        deleteOrder(orderNo) {
            this.newApi.delShopGoodsOrder({orderNo: orderNo}).then(res => {
                if (res.isSuccess === 1) {
                    this.utils.sus(res.data);
                    this.$emit('updateList')
                }
            })
        },

        // 确认发货
        makeSendGoods() {
            let params = {}
            params.smallOrderNo = this.sendId;
            params.isPay = 2;
            params.expressNumber = this.reviewData.expressNumber;
            params.isInvoice = this.reviewData.isInvoice;
            if (!params.expressNumber) {
                this.utils.err('请填写物流单号');
                return false;
            }
            this.newApi.sellerUpdateGoodsOrder(params).then(res => {
                if (res.isSuccess === 1) {
                    this.utils.sus(res.data);
                    this.showSend = false;
                    this.$emit('updateList')
                }
            })
        },

        // 确认收货
        makeReceive(id) {
            let that = this;
            this.utils.confirm('确认收货将无法申请退货，请确认无误后再确认', function () {
                that.newApi.confirmReceipt({smallOrderNo: id}).then(res => {
                    if (res.isSuccess === 1) {
                        that.utils.sus(res.data);
                        that.$emit('updateList')
                    }
                })
            })

        },

        // 立即支付
        payTheOrder(item) {
            let params = {};
            params.cartList = [];
            params.orderNo = this.newOrderNo;
            for (const i of item.goodsList) {
                params.cartList.push(i.id)
            }
            params.cartList = JSON.stringify(params.cartList)
            this.newApi.shopGoodsResumePay(params).then(res => {
                if (res.isSuccess === 1) {
                    this.payOne = res.data;
                    this.showPay = true;
                }
            })
        },

        //子组件传过来的订单号 此方法在父页面必写
        changeOrder(order){
            this.newOrderNo = order;
        },

        //父组件获取子组件的验证码 this.$refs.child.childMethods() 必写
        toPaySelect(){
            var that = this;
            if (that.user.userType === 1) {
                var obj = this.$refs.child.childMethods();
                if (obj.code == "") {
                    that.utils.err("请填写验证码");
                    return false;
                }
                if (obj.payPwd == "") {
                    that.utils.err("请填写密码");
                    return false;
                }
                let params = {};
                params.orderNo = that.newOrderNo;
                params.code = obj.code;
                params.payPwd = obj.payPwd;

                that.toPay(params);
            }
        },

        // 支付
        toPay(params) {
            var that = this;
            that.loading = true;
            that.newApi.settleAccountPennyDetermine(params).then((res) => {
                that.loading = false;
                if(res.isSuccess == 1){
                }
            })
        },

        // 申请退款
        applyRefund(orderNo) {
            console.log(orderNo)
            const to = this.$router.resolve({
                path: '/shop/afterSale',
                query: {smallOrderNo: orderNo}
            })
            window.open(to.href, '_blank')
        },
    }
}
</script>

<style scoped>
.produce-card {
    background-color: #FFFFFF;
    border: 1px solid #BDBFC1;
}

.produce-card .title-info {
    height: 42px;
    line-height: 42px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    background-color: #F5F5F5;
    color: #999999
}

.produce-card .body-box {
    background-color: #FFFFFF;
    display: flex;
}

.produce-card .content-bord {
    font-size: 16px;
    font-weight: 600;
    text-overflow:ellipsis;
    -webkit-line-clamp:2;
    -webkit-box-orient:vertical;
    overflow:hidden;
    display:-webkit-box;
}

.produce-card .content-normal {
    font-size: 14px;
    color: #999999;
}

.content-status {
    font-size: 14px;
    margin-bottom: 6px;
    text-align: center;
}

.produce-card .body-option {
    text-align: center;
    font-weight: 600;
    padding-top: 40px;
}

.produce-card .btn {
    padding: 2px 10px;
    display: inline-block;
    border-radius: 5px;
}

.produce-card .foot-btn {
    width: 108px;
    height: 24px;
    font-size: 16px;
    border-radius: 5px;
    text-align: center;
    padding: 6px;
}

.produce-card .box-head {
    padding: 10px;
    font-size: 18px;
    font-weight: 600;
}

.produce-card .title-form {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 12px;
}

/deep/ .event-form .el-dialog__header {
    padding: 0;
}

/deep/ .event-form .el-dialog__body {
    padding: 0;
}

.dialog-title {
    height: 30px;
    padding: 10px 0;
    font-weight: 600;
    font-size: 20px;
}

.event-form .top {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #444444;
  border: 1px solid #CECFD0;
  height: 60px;
  box-sizing: border-box;
  line-height: 60px;
}

/deep/ .show-message .el-dialog__header{
    padding: 0;
}

/deep/ .show-message .el-dialog__body{
    padding: 0;
}
</style>
