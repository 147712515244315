<template>
    <div class="order-card">
        <div class="title-info">
            <div style="display: flex">
                <div>
                    {{orderData.createTime}}
                </div>
                <div style="margin: 0 180px 0 40px">

                </div>
                <div style="display: flex">
                    <span>店铺：</span>
                    <span>{{orderData.shopName}}</span>
                </div>
            </div>

            <div class="hand op" @click="toMessage(orderData.sellerUuid, orderData.shopUuid, '服务方')" style="color: #031F88; font-weight: 600; margin-right: 10px">
                <i class="el-icon-service"></i> 联系服务方
            </div>
        </div>

        <div class="body-box" v-if="orderData.demandInfo">
            <div style="padding: 20px; display: flex;">
                <img v-if="orderData.demandInfo.images" :src="orderData.demandInfo.images[0].url" style="width: 90px; height: 90px; margin-right: 10px;">
                <div style="width: 450px">
                    <div class="content-bord">{{orderData.demandInfo.name}}</div>
                    <div class="content-normal" style="margin-top: 10px" v-html="orderData.demandInfo.demand"></div>
                </div>
            </div>
            <div class="body-option" style="width: 90px">1</div>
            <div class="body-option" style="width: 200px; border-right: 1px solid #E9E9E9;">{{orderData.buyerInfo.nickName}}</div>
<!--            <div class="body-option" style="width: 200px; border-right: 1px solid #E9E9E9;">委托方</div>-->

            <div class="body-option" style="width: 200px; border-right: 1px solid #E9E9E9;">
                <div class="content-bord" v-if="orderData.payInfo">￥{{orderData.payInfo.price}}</div>
                <div class="content-bord" v-else>无付款信息</div>
                <div class="content-normal">（含运费：￥0.00）</div>
            </div>

            <div class="body-option" style="width: 200px; border-right: 1px solid #E9E9E9;">
                <div class="content-status">待签约</div>
            </div>

            <div class="body-option" style="width: 130px">
                <div @click="toServiceInfo(orderData)" class="content-bord hand op" style="color: #2970FF">查看订单</div>
            </div>
        </div>

        <div v-else class="body-box">
            <div style="padding: 20px; display: flex;" v-if="orderData.talbeInfo">
                <img v-if="orderData.talbeInfo.images"  :src="orderData.talbeInfo.images[0].url" style="width: 90px; height: 90px; margin-right: 10px;">
                <div style="width: 450px">
                    <div class="content-bord">{{orderData.talbeInfo.name}}</div>
                    <div class="content-normal" style="margin-top: 10px" v-html="orderData.talbeInfo.demand"></div>
                </div>
            </div>
            <div v-else style="width: 590px"></div>
            <div class="body-option" style="width: 90px">1</div>
            <div class="body-option" style="width: 200px; border-right: 1px solid #E9E9E9;">{{orderData.buyerInfo.nickName}}</div>
            <!--            <div class="body-option" style="width: 200px; border-right: 1px solid #E9E9E9;">委托方</div>-->

            <div class="body-option" style="width: 200px; border-right: 1px solid #E9E9E9;">
                <div class="content-bord" style="color: #FF0000">￥{{orderData.totalAmount}}</div>
                <div class="content-normal">（含运费：￥0.00）</div>
            </div>

            <div class="body-option" style="width: 200px; border-right: 1px solid #E9E9E9;">
                <div v-for="(item, index) in orderStatusList" :key="index">
                    <div class="content-status" :style="{color: item.color}" v-if="item.status === orderData.status">{{item.name}}</div>
                </div>
            </div>

            <div class="body-option" style="width: 130px">
                <div @click="toServiceInfo(orderData)" class="content-bord hand op" style="color: #2970FF">查看订单</div>
            </div>
        </div>

        <el-dialog :close-on-click-modal="false" class="show-message" :visible.sync="showMessage" width="940px">
            <div class="dialog-title">
                <div style="margin-left: 40%">
                    <span style="color: #0084FF">{{nickName}}</span>
                    <span>的私信</span>
                </div>
            </div>
            <el-divider></el-divider>
            <div style="padding: 20px">
                <ToMessage :uuid="uuid" :type="1"></ToMessage>
            </div>
        </el-dialog>
    </div>
</template>

<script>

import ToMessage from "./ToMessage";
export default {
    name: "OrderCard",
    components: {ToMessage},
    data() {
        return {
            orderStatusList: [
                {name: '待缴保证金', color: '#F44336', status: 2},
                {name: '待付款', color: '#F44336', status: 3},
                {name: '进行中', color: '#FF9800', status: 4},
                {name: '待验收', color: '#FF9800', status: 5},
                {name: '已完成', color: '#8BC34A', status: 9},
                {name: '已撤单', color: '#999999', status: 10},
                {name: '退款中', color: '#999999', status: 7},
            ],

            showMessage: false,
            uuid: '',
            nickName: '',
        }
    },
    props: {
        orderData: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    created: function () {

    },
    mounted: function () {

    },
    methods: {
        // 查看订单详情
        toServiceInfo(orderData) {
            if (orderData.orderNo) {
                this.utils.a('/shop/serviceInfo/' + orderData.orderNo)
            } else {
                this.utils.a('/shop/orderInfo/' + orderData.id)
            }
        },

        // 私信
        toMessage(uuid, sUuid, name) {
            if (uuid) {
                this.uuid = uuid;
            } else {
                this.uuid = sUuid;
            }
            this.nickName = name;
            this.showMessage = true
        },
    }
}
</script>

<style scoped>
    .order-card {
        background-color: #FFFFFF;
        border: 1px solid #BDBFC1;
    }

    .order-card .title-info {
        height: 42px;
        line-height: 42px;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        background-color: #F5F5F5;
        color: #999999
    }

    .order-card .body-box {
        background-color: #FFFFFF;
        display: flex;
    }

    .order-card .content-bord {
        font-size: 16px;
        font-weight: 600;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden
    }

    .order-card .content-normal {
        max-height: 70px;
        font-size: 14px;
        color: #999999;
        text-overflow:ellipsis;
        -webkit-line-clamp:3;
        -webkit-box-orient:vertical;
        overflow:hidden;
        display:-webkit-box;
    }

    .content-status {
        font-size: 14px;
        margin-bottom: 6px;
        text-align: center;
    }

    .order-card .body-option {
        text-align: center;
        font-weight: 600;
        padding-top: 20px;
    }

    .dialog-title {
        height: 30px;
        padding: 10px 0;
        font-weight: 600;
        font-size: 20px;
    }

    /deep/ .show-message .el-dialog__header{
        padding: 0;
    }

    /deep/ .show-message .el-dialog__body{
        padding: 0;
    }
</style>
